import React from "react";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');
        </style>
      </header>
      <body className="app-body">
        <div className="left-container">
          <div
            style={{
              paddingLeft: "120px",
              paddingTop: "80px",
              fontFamily: "IowanOldStyle",
            }}
          >
            <p style={{ fontSize: "64px" }}>
              Hi I’m Sanjeev Sreetharan, a machine learning engineer based in
              NYC, welcome to my website :)
            </p>
          </div>
          <div>
            <div style={{ paddingLeft: "144px", paddingTop: "0px" }}>
              <p
                style={{
                  fontSize: "48px",
                  color: "#C0C0C0",
                  fontFamily: "Inconsolata",
                }}
              >
                Projects:
                <ul>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://www.readbitbooks.com">Bitbooks</a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="right-container"></div>
      </body>
    </div>
  );
}

export default App;
