import React from "react";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');
        </style>
      </header>
      <body>Hi, this is the blog</body>
    </div>
  );
}

export default App;
